import { render, staticRenderFns } from "./ContentIndex.vue?vue&type=template&id=665a712c&scoped=true&"
import script from "./ContentIndex.vue?vue&type=script&lang=js&"
export * from "./ContentIndex.vue?vue&type=script&lang=js&"
import style0 from "./ContentIndex.vue?vue&type=style&index=0&id=665a712c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665a712c",
  null
  
)

export default component.exports