<template>

  <div
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
    v-if="content"
  >
    <router-link
      tag="div"
      :to="{name: 'wiki.content', params: { world: world.id, content: content.id } }"
      :class="{
        'py-1 px-1 rounded cursor-pointer': true,
        'bg-greylight text-dark': hovered,
      }"
    >
      <div class="d-flex align-items-center w-100 justify-content-between">
        <div class="container">
          <div class="row no-gutters align-items-center">
            <div class="col-md-6">
              <div class="d-flex align-items-center">
                <el-avatar size="small" v-if="content.category_id" :src="require('@/assets/images/categories/' + content.category_id + '.svg')" />
                <el-avatar size="small" fit="contains" v-else :src="require('@/assets/images/mascot-circle.svg')" />
                <div class="pl-2">
                  <span class="small text-grey" v-if="content.category_id">{{ getCategoryTitle(content.category_id) }}</span>
                  <span class="small text-grey" v-else>{{ $t('common.to_classify') }}</span>
                  <div class="font-weight-bold">{{ content.name }}</div>
                </div>
              </div>
            </div>
            <div class="col-md-4 small text-grey text-lowercase">
              {{ updatedAtAgo }}
            </div>
            <div class="col-md-2">
              <el-avatar size="small" v-if="content.user" :src="content.user.avatar.thumb" v-tooltip="content.user.full_name"/>
            </div>
          </div>
        </div>
        <div>
          <i class="icon-arrow-right8 mr-2" />
        </div>
      </div>
    </router-link>
  </div>

</template>

<script>

import moment from 'moment'

/**
 * @group _ Module Wiki
 * Content Teaser
 */
export default {
  name: 'WikiContentTeaser',
  tag: 'wiki-content-teaser',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      hovered: false,
    }
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld']
    },
    updatedAtAgo () {
      if (!this.content) {
        return null
      }
      let date = moment(this.content.updated_at).fromNow()
      return this.$t('moduleentity.informations.updated', { date })
    },
  },
  methods: {
    getCategoryTitle (categoryLabel) {
      return this.$te('modules.quest.categories.' + categoryLabel) ? this.$t('modules.quest.categories.' + categoryLabel) : categoryLabel
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
